import { cn } from "@/lib/utils";
import LockAppBanner from "components/DigitalKey/LockAppBanner";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import React, { useCallback, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Arrow } from "./icons/arrow.svg";
import { ReactComponent as CloseX } from "./icons/close.svg";
import { ReactComponent as LockedIcon } from "./icons/locked.svg";
import { ReactComponent as UnlockedIcon } from "./icons/unlocked.svg";

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const LockPopup = ({
  locks = [],
  show,
  onClose,
  onSetLock,
  displayAppDownload = false,
}) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={show}
      unmountOnExit
      mountOnEnter
      timeout={300}
      nodeRef={nodeRef}
      classNames={{
        enterActive: "motion-safe:animate-lockAppear",
        enterDone: "left-0",
        exitActive: "motion-safe:animate-lockDisappear",
      }}
    >
      <div ref={nodeRef}>
        {displayAppDownload && (
          <LockAppBanner
            title="Room Key - Unlock Your Door"
            description="Get the Room Key app"
          />
        )}
        <div
          data-body-scroll-lock-ignore={true}
          className={cn("fixed bottom-0 right-0 z-40 max-h-[90%] w-full", {
            "h-full": locks.length >= 3,
          })}
        >
          <div className="relative right-0 top-0 z-10">
            <ExitButton onClick={onClose} />
          </div>
          <div className="h-full overflow-y-scroll bg-dark-gray p-4">
            <div className="overflow-y-scroll">
              {locks.map((data) => {
                const { id, roomId, ...lock } = data;
                return (
                  <LockInterface
                    key={id}
                    id={id}
                    roomId={roomId}
                    lock={lock}
                    onSetLock={onSetLock}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

const LockInterface = ({ id, roomId, lock, onSetLock }) => {
  const handleThrow = useCallback(
    (x) => {
      if (x === 0) onSetLock({ id, roomId, state: "locked" });
      else onSetLock({ id, roomId, state: "unlocked" });
    },
    [id, roomId, onSetLock],
  );

  return (
    <div className="relative mb-8 only-of-type:mb-0">
      <LockInfo name={lock.name} state={lock.state} />
      <LockSlider
        key={lock.id}
        state={lock.state}
        onSlideComplete={handleThrow}
      />
    </div>
  );
};

const LockSlider = ({ state, onSlideComplete }) => {
  const lockInput = useRef(null);
  const boundary = useRef(null);

  const isUnlocked = state === "unlocked";
  const isLocked = state === "locked";

  const initSlider = useCallback(() => {
    const inputWidth = lockInput?.current?.offsetWidth;
    const borderWidth = 8 * 2;
    const unlockValueX =
      boundary.current.offsetWidth - borderWidth - inputWidth;

    Draggable.create(lockInput.current, {
      type: "x",
      bounds: boundary.current,
      inertia: true,
      lockAxis: true,
      edgeResistance: 1,
      throwResistance: 1000,
      snap: {
        x: function (val) {
          const max = unlockValueX * 0.95;
          if (val >= max) return unlockValueX;
          else return 0;
        },
      },
      onThrowComplete: function () {
        onSlideComplete(this.x);
      },
    });
  }, [onSlideComplete]);

  const center = "h-20 w-20 flex items-center justify-center";
  const iconColor = "#6a738a";

  return (
    <CSSTransition
      in
      appear
      mountOnEnter
      unmountOnExit
      timeout={300}
      onEntered={initSlider}
      nodeRef={boundary}
    >
      <div
        ref={boundary}
        className="relative flex h-24 items-center rounded-[48px] border-8 border-slate-600 bg-slate-600"
      >
        <button
          ref={lockInput}
          className={cn(
            `${center} relative z-10 rounded-full transition-colors`,
            isLocked ? "bg-slate-500" : "ml-auto bg-green",
          )}
        >
          {isLocked && <LockedIcon color="white" />}
          {isUnlocked && <UnlockedIcon />}
        </button>

        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-between">
          <div className={center}>
            <LockedIcon color={iconColor} />
          </div>
          <div className={center}>
            <Arrow color={iconColor} />
          </div>
          <div className={center}>
            <UnlockedIcon color={iconColor} />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

const LockInfo = ({ name = "Room Door", state = "locked" }) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in
      appear
      mountOnEnter
      timeout={300}
      classNames={{}}
      nodeRef={nodeRef}
    >
      <div ref={nodeRef} className="relative mb-8">
        <h3 className="mb-2 text-center text-3xl font-medium">{name}</h3>
        <div className="text-center">
          <span
            className={cn(
              "rounded-2xl px-2 py-1 text-center uppercase transition duration-100",
              {
                "bg-green": state === "unlocked",
                "bg-slate-500": state === "locked",
              },
            )}
          >
            {state === "locked" ? "Locked" : "Unlocked"}
          </span>
        </div>
      </div>
    </CSSTransition>
  );
};

const ExitButton = ({ onClick }) => {
  return (
    <div className="absolute right-0 top-0 p-4 opacity-50">
      <button onClick={onClick} className="float-right">
        <CloseX className="float-right" color="#9AA1B0" />
      </button>
    </div>
  );
};

export default LockPopup;
