import RoomLock from "components/RoomControl/RoomLock";
import { useAuth } from "hooks/use-auth";
import { useProperty } from "hooks/use-property";
import { useRoomControl } from "hooks/use-room-control";
import React from "react";
import { pickDevices } from "utils/room-control";
import { sortByName } from "utils/sort";

const FloatingLocks = () => {
  const { isHospitality } = useProperty();
  const { spaces, currentRole } = useAuth();
  const { commonAreas, currentSpaceId, data } = spaces;

  const spaceScope =
    currentRole === "Guest" ? commonAreas?.map((s) => s.id) : [];

  const { rooms, setDevice } = useRoomControl({
    spaces: data,
    currentSpaceId,
    spaceScope,
  });

  const locks = pickDevices(rooms, "locks").sort(sortByName);

  const handleSetLock = ({ id, roomId, ...lock }) => {
    setDevice({
      id,
      roomId,
      spaceId: currentSpaceId,
      deviceType: "lock",
      ...lock,
    });
  };

  if (!isHospitality) return null;

  return <RoomLock locks={locks} onChange={handleSetLock} />;
};

export default FloatingLocks;
